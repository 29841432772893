var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/api/tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"fPZZktffYhYLOC9LsOiWt"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const SENTRY_DSN =
  publicRuntimeConfig.SENTRY_DSN || publicRuntimeConfig.NEXT_PUBLIC_SENTRY_DSN;

if (process.env.DEPLOY_TYPE !== 'local') {
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://706783ae10f54f7e97c362adb8ed0a4b@o525849.ingest.sentry.io/4504241825447936',

    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    debug: false,

    replaysOnErrorSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    environment: publicRuntimeConfig.DEPLOY_TYPE || 'not_set',
    tunnel: '/api/tunnel',
  });
}
